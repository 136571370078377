import React, { useEffect, useState, useRef } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import app from '../firebase';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Signin.css'; // Import the CSS file
import Header from './Header';
import Footer from './Footer';
import Firms from './Firms';
import Schools from './Schools';
import FAQ from './FAQ';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format } from 'date-fns';
import { getFirestore, doc, setDoc  } from "@firebase/firestore";
import demoVideo from "./OverleveredDemo1.mov"
import demoVideoPoster from "./OverleveredDemoPoster.png"

const analytics = getAnalytics();
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Generate Random Id for Login Table
function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }
  return randomId;
}

function Signin () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); 
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user is signed in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
        const userDisplayName = user.displayName;
        const userEmail = user.email;
        navigate('/platform', { state: { userDisplayName, userEmail } });
      } else {
        // User is not signed in
      }
    });
    return () => unsubscribe(); 
  }, [navigate]);

  // Sign up user
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const displayName = user.displayName;
        const email = user.email;        
        
        // Push to Login table
        const now = new Date();
        const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
        getIPAddress(user.email, formattedDateTime);

        setDoc(doc(db, "users", user.email), {
          displayName: user.displayName,
          uid: user.uid,
          email: user.email,
          lastLogin: formattedDateTime
        });

        navigate('/pricing', { state: { displayName, email } });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const getIPAddress = async (userEmail, formattedDateTime) => {
    try {
        // Making a request to the ipify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const userIP = data.ip;
        await setDoc(doc(db, "logins", generateRandomId(8)), {
          page: 'landing',
          timestamp: formattedDateTime,
          userEmail: userEmail,
          ip: userIP
        });
    } catch (error) {
        // 'Error fetching the IP address
        await setDoc(doc(db, "logins", generateRandomId(8)), {
          page: 'landing',
          timestamp: formattedDateTime,
          userEmail: userEmail
        });
    }
}

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollToBottom = () => {
    const bottomElement = document.getElementById('contact-bottom');
    if (bottomElement) {
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToPricing = () => {
    const pricingElement = document.getElementById('pricing-div');
    if (pricingElement) {
      pricingElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContactClick = () => {
    handleToggleMenu();
    handleScrollToBottom();
  };

  const handlePricingClick = () => {
    handleToggleMenu();
    handleScrollToPricing();
  };

  return (
    <div>
      <Header
        handleToggleMenu={handleToggleMenu}
        handleScrollToPricing={handleScrollToPricing}
        handleScrollToBottom={handleScrollToBottom}
        handleSignup={handleSignup}
        handlePricingClick={handlePricingClick}
        handleContactClick={handleContactClick}
        menuOpen={menuOpen}
      />

      {/* HERO */}
      <div class="bg-transparent relative isolate px-6 pt-14 lg:px-8">
        
        <div class="mx-auto max-w-2xl py-32 lg:py-48">
          {/* <div class="mb-8 flex justify-center">
            <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-light-blue">
              New questions added every week
            </div>
          </div> */}
          <div class="text-center">
            <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Finance Interview Prep, Made Simple</h1>
            <p class="mt-6 text-lg leading-8 text-gray-600">Practice with Interview Questions Previously Asked by Top Investment Banks and Private Equity Firms.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <a href="#" class="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black" onClick={handleSignup}>Join Today</a>
              {/* <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a> */}
            </div>
            <div class="md:py-12 lg:py-16"></div>
          </div>
        </div>

        
      </div>

      {/* DEMO */}
      <div class="bg-grey-3 py-8">
        <div class="text-center mb-4">
          <h2 class="text-xl font-semibold text-gray-800">Watch our demo</h2>
        </div>
        <div class="flex justify-center">
          <video playsInline autoPlay muted controls loop class="mx-auto" style={{ width: '90%', maxWidth: '600px', borderRadius: '8px' }} poster={demoVideoPoster} src={demoVideo}></video>
        </div>
      </div>

      {/* FRIM LOGOS */}
      <div class="bg-white py-24 sm:py-32">
        <Firms/>
      </div>

      {/* PRICING */}
      <section class="bg-white dark:bg-gray-900">
      <div class="px-4 mx-auto max-w-screen-xl" id="pricing-div">
          <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h2 class="mb-4 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">Pricing and Plans</h2>
              <p class="mb-5 font-light italic text-gray-500 sm:text-lg dark:text-gray-400">"<span class="itlic"><span class="text-light-blue">Overlevered</span> helped me land offers at Morgan Stanely and PJT NY M&A! – Sophomore from UChicago"</span> </p>
          </div>
          <div class="space-y-5 sm:gap-1 lg:grid lg:grid-cols-3 lg:space-y-0 xl:gap-10">
              <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-grey border-2 shadow dark:border-grey dark:bg-black dark:text-white px-0 pt-0">
                  <div className="bg-grey text-grey text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                  %
                  </div>
                  <h3 class="mb-4 text-xl font-semibold">1 Month</h3>
                  <div class="flex justify-center items-baseline my-8">
                      <span class="mr-2 text-5xl font-bold">$12</span>
                      <span class="text-gray-500 dark:text-gray-400">/month</span>
                  </div>
                  <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$12 total</p>
                  <a href="#" class="text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" onClick={handleSignup}>Get started</a>
              </div>
              <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-black border-4 shadow dark:border-black dark:bg-black dark:text-white px-0 pt-0">
                  <div className="bg-black text-white text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                   Save 33%
                  </div>
                  <h3 class="mb-4 text-xl font-semibold">3 Months</h3>
                  <div class="flex justify-center items-baseline my-8">
                      <span class="mr-2 text-5xl font-bold">$8</span>
                      <span class="text-gray-500 dark:text-gray-400">/month</span>
                  </div>
                  <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$24 total</p>
                  <a href="#" class="text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" onClick={handleSignup}>Get started</a>
              </div>
              <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-grey border-2 shadow dark:border-grey dark:bg-black dark:text-white px-0 pt-0">
                  <div className="bg-grey text-black text-center rounded-t-sm py-2 lg:w-80 xl:w-80 py-2 mb-4">
                  Save 50%
                  </div>
                  <h3 class="mb-4 text-xl font-semibold">6 Months</h3>
                  <div class="flex justify-center items-baseline my-8">
                      <span class="mr-2 text-5xl font-bold">$6</span>
                      <span class="text-gray-500 dark:text-gray-400">/month</span>
                  </div>
                  <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-10">$36 total</p>
                  <a href="#" class="text-white bg-primary hover:bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-10 mx-10 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" onClick={handleSignup}>Get started</a>
              </div>
          </div>
      </div>
    </section>

    {/* SCHOOL LOGO CLOUD */}
    <div class="bg-white py-24 sm:py-32">
      <Schools/>
    </div>

    {/* CLUBS */}
    <section class="bg-gray-900">
      <div class="px-4 mx-auto max-w-screen-xl pt-6" id="pricing-div">
          <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 class="text-center text-lg font-semibold leading-8 text-gray-200 ">For Clubs</h2>
          <h2 class="text-3xl tracking-tight font-bold text-white dark:text-white">Groups and University Clubs</h2>
          <p class="mt-2 pb-4 font-light text-gray-100 sm:text-lg dark:text-gray-400">Unlock significantly discounted access for your members (10+ accounts)</p>
          <div class="pb-8 flex items-center justify-center gap-x-6">
            <a href="mailto:patrick@overlevered.com?subject=Hi!%20Tell%20me%20more%20about%20your%20group%20discounts%20for%2010%2B%20accounts" class="rounded-md bg-light-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">Enquire Now</a>
          </div>
          </div>
      </div>
    </section>

    <FAQ/>
    <div class="pb-32"/>

    

    <Footer />
</div>
  );
}

export default Signin;